<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")
    .widget
      .widget-header
        h1.title Finance Overview
        router-link.btn.btn-circle.btn-primary(:to="{name:'finance-summary'}")
          i.la.la-chart-pie
      .widget-body

        form.form.form-inline.ov-filters(@submit.prevent="loadData()")
          .form-group
            year-picker(v-model="filters.year")

          .form-group
            label &nbsp;
            button.btn.btn-primary(type="submit") Go
        p.clearfix &nbsp;

        .ov-table-wrapper
          table.table.table-bordered.table-finance
            thead
              tr
                th Date
                th Demand
                th Supply
                th GP
                th Actions
            tbody
              tr(v-if="records.length===0")
                td(colspan="5") No matching records were found
              tr(v-for="r in records", :class="{'row-success': r.profit > 0 && !+r.adv_estimated_usd && !+r.pub_estimated_usd}")
                td {{ r.date_key }}
                td
                  div {{ +r.adv_confirmed_usd | toCurrency }}
                  small(v-if="+r.adv_estimated_usd") ~ {{ +r.adv_estimated_usd | toCurrency }}
                td
                  div {{ +r.pub_confirmed_usd | toCurrency }}
                  small(v-if="+r.pub_estimated_usd") ~ {{ +r.pub_estimated_usd | toCurrency }}
                td
                  div(v-if="+r.adv_estimated_usd || +r.pub_estimated_usd", style="color: #666") {{ +r.profit - +r.pub_estimated_usd | toCurrency }} ~ {{ +r.profit + +r.adv_estimated_usd | toCurrency }}
                  div(v-else) {{ +r.profit | toCurrency }}

                td.actions
                  router-link.btn.btn-sm.btn-secondary(:to="{name:'finance-summary', params: {month:r.date_key}}")
                    i.la.la-search
            //tfoot.summary
              tr(v-if="summary")
                th {{ records.length }} Months
                th
                  div {{ summary.adv_confirmed_usd | toCurrency }}
                  small(v-if="summary.adv_estimated_usd") ~ {{ summary.adv_estimated_usd | toCurrency }}
                td
                  div {{ summary.pub_confirmed_usd | toCurrency }}
                  small(v-if="summary.pub_estimated_usd") ~ {{ summary.pub_estimated_usd | toCurrency }}
                td
                  div {{ summary.profit | toCurrency }}
                  div {{ summary.profit_avg | toCurrency }}
                    small &nbsp; / month
                th &nbsp;





</template>
<style lang="scss">
.table-finance {
  tbody {
    tr {
      td {
        &.success {
          background: lighten(#40dc7e, 35) !important;
        }
      }
    }
  }

  .badge {
    font-size: 12px;
  }
}
</style>

<script>
import moment from 'moment';

export default {
  name: 'FinanceSummary',
  data() {
    return {
      busy: false,
      filters: {
        year: moment().year(),
      },
      records: [],
      summary: false,
    };
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let params = {
        year: this.filters.year,
      };
      this.summary = false;
      try {
        let resp = await this.$ovReq.get('finance/summary/getList', { params });
        this.records = resp.records;
        this.summary = resp.records.reduce(
          (s, r) => {
            s.adv_estimated_usd += +r.adv_estimated_usd;
            s.adv_confirmed_usd += +r.adv_confirmed_usd;
            s.pub_estimated_usd += +r.pub_estimated_usd;
            s.pub_confirmed_usd += +r.pub_confirmed_usd;
            s.profit += +r.profit;
            return s;
          },
          {
            adv_estimated_usd: 0,
            adv_confirmed_usd: 0,
            pub_estimated_usd: 0,
            pub_confirmed_usd: 0,
            profit: 0,
            profit_avg: 0,
          },
        );
        this.summary.profit_avg = this.summary.profit / resp.records.length;
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },
  },

  created() { },
};
</script>
